import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Analytics } from "./Analytics";

import { HomePage } from "../pages/HomePage";
import { TermsOfUsePage } from "../pages/TermsOfUsePage";
import { Page404 } from "../pages/404Page";
import { PrivacyPolicyPage } from "../pages/PrivacyPolicyPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUsePage />,
  },
  {
    path: "/404",
    element: <Page404 />,
  },
  {
    path: "*",
    element: <Page404 />,
  },
]);

function App() {
  return <>
    <RouterProvider router={router} />
    <Analytics />
  </>;
}

export default App;
