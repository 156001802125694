import "./Navigation.scss";

import {Link} from "react-router-dom";
import React from "react";


export function Naivgation() {
    return (
        <nav className="top-navigation">

            <Link to="/" className="top-navigation__logo">
                <img width={32} height={32} src="/img/logo.svg" alt="Logo"/>
                Short Route
            </Link>


            <ul className="top-navigation__links">
                <li>
                    <Link to="/#main">Home</Link>
                </li>
                <li>
                    <Link to="/#services" onClick={() => {
                        document.getElementById("services")
                            ?.scrollIntoView()
                    }}>Services</Link>
                </li>
                <li>
                    <Link to="/#faq" onClick={() => {
                        document.getElementById("faq")
                            ?.scrollIntoView()
                    }}>FAQ</Link>
                </li>
            </ul>

            <div className="top-navigation__right">

                <a className="top-navigation__log-in" href={process.env.REACT_APP_SPACE_URL}>
                    Log In
                </a>

                <a
                    className="button"
                    href={`${process.env.REACT_APP_SPACE_URL}/sign-up`}
                >
                    Sign up
                </a>

            </div>
        </nav>
    );
}
