import "./Footer.scss";

import React from "react";
import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className="footer">
      <div className="content">
          <div className="sign">@ 2024 Short-Route.com | All Rights Reserved</div>
          <div className="links">
              <Link to="/terms-of-use">Terms Of Use</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
      </div>
    </footer>
  );
}
