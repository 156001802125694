import "./HomePage.scss";

import React from "react";

import {Head} from "../components/Head";
import {Layout} from "../components/Layout";

const FAQItem = ({
    question,
    answer
                 }: {
    question: string,
    answer: string
}) => {

    return (
        <li>
            <article className="list-content">
                <h3>{question}</h3>
                <p>{answer}</p>
            </article>
        </li>
    );
}

export function HomePage() {
    return (
        <Layout>
            <Head
                title="URL Shortener, Custom Short URLs, Free Link Shortener | Short Route"
                keywords="url shortener, tiny url, link shortening, short url, shortening url, shorten url, shortener url, link shortener, short link, shorten links, shorter link, shortlink, free url shortener, short route, qr code generator, qr code"
                description="Short Route is a platform for URL shortening and link management. Easily generate custom short links, branded links, and QR codes with our free link shortener service."
            />

            <section className="section first-section">
                <div className="content">
                    <h1 className="slug">Create Short URLs</h1>
                    <p className="main-text">
                        We are shortening service that specializes in creating, monitoring,<br/> branding, and sharing
                        short URLs.
                    </p>

                    <form className="form" onSubmit={e => {
                        e.preventDefault();
                        // @ts-ignore
                        window.location.href = process.env.REACT_APP_SPACE_URL;
                    }}>
                        <label className="label">How about trying it?</label>
                        <input placeholder="Type your URL here" className="input"/>
                        <button className="input-button">Short It</button>
                    </form>
                </div>
            </section>

            <section id="services" className="section services">
                <div className="content">
                    <article className="item">
                        <h2>Shorten URLs</h2>
                        <p>
                            The Short-Route.com URL Shortener transforms lengthy links into
                            tidy, shareable ones! Customize your brand with your own Custom
                            Domains and enjoy the trust of over 20 million monthly visitors.
                            Plus, effortlessly generate trackable QR Codes.
                        </p>
                    </article>

                    <article className="item">
                        <h2>Track Link Clicks</h2>
                        <p>
                            Short-Route.com has shortened and tracked more than 40 million
                            links, recording over 700 million link clicks. It provides
                            valuable insights into user origins and serves as a click counter
                            tool for comprehensive link analytics
                        </p>
                    </article>

                    <article className="item">
                        <h2>QR Codes</h2>
                        <p>
                            Short-Route.com enables you to effortlessly create and personalize
                            QR codes that align with your brand while also monitoring their
                            performance. Leverage QR codes to expand your business reach and
                            evaluate their effectiveness in your marketing campaigns.
                        </p>
                    </article>
                </div>
            </section>

            <section className="section platform">
                <div className="content">
                    <blockquote className="slug">Simplify</blockquote>
                    <h2 className="title">
                        Short-Route.com More Than Just a URL Shortener
                    </h2>
                    <p className="desc">
                        Short-Route.com is an advanced platform for URL shortening that<br/>
                        continuously evolves, blending innovation with ease of use to<br/>
                        provide a smooth experience in managing and shortening links.
                    </p>
                    <a href={`${process.env.REACT_APP_SPACE_URL}/sign-up`} className="button">Sign Up</a>
                </div>
            </section>

            <section className="section faq" id="faq">
                <div className="content">
                    <h2>Frequently Asked Questions</h2>
                    <ul className="list">
                        <FAQItem
                            question="What is a short link?"
                            answer="A shortened link is a compact rendition of a lengthy web address (URL) crafted through a URL shortening service. These concise URLs are intended to be visually attractive, effortlessly shareable, and ideal for platforms with character constraints, like social media, text messages, and emails. They prove particularly useful for sharing content in scenarios with limited space or when aiming for a neat and streamlined presentation."
                        />

                        <FAQItem
                            question="Is Short Route free URL shortener?"
                            answer="Short Route is a no-cost URL shortening service enabling users to generate concise, personalized, and traceable URLs."
                        />

                        <FAQItem
                            question="Do short links created with Short Route expire?"
                            answer="Short links generated using Short-Route do not have an expiration date."
                        />

                        <FAQItem
                            question="Can I use my own custom or branded domains with Short-Route for URL shortening?"
                            answer="Of course! Short Route is a flexible URL shortening tool that empowers you to integrate your personalized or branded domains for creating shortened URLs. This functionality ensures a consistent brand identity while utilizing short links."
                        />
                    </ul>
                </div>
            </section>
        </Layout>
    );
}
